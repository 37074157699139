.datepicker_field {
    width: 100%;
}

.section_title {
    font-weight: 500;
}

.top_form_item {
    margin-top: 0.75rem;
}

.btm_form_item {
    margin-bottom: 2.5rem;
}

.modal_divider {
    margin: 1rem 0;
}

.alert_success {
    background-color: var(--alert-success);
    border: 0.0625rem solid var(--alert-success-border);
}

.alert_error {
    background-color: var(--alert-error);
    border: 0.0625rem solid var(--alert-error-border);
}