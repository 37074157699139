.modal_content_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.modal_content_container :global(.ant-select-selection-search-input) {
    color: var(--text-color)
}

.modal_content_container :global(.ant-form-item) {
    margin-bottom: 32px;
}

.modal_content_container :global(.ant-form-item) :global(.ant-col-4) {
    max-width: 140px;
    flex: 0 0 140px;
}

.alert_success {
    background-color: var(--alert-success);
    border: 0.0625rem solid var(--alert-success-border);
}

.alert_error {
    background-color: var(--alert-error);
    border: 0.0625rem solid var(--alert-error-border);
}

.description_input>textarea {
    resize: none;
}