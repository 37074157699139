.footer_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1.5rem;
  padding-right: 1rem;
}

.total_percentage {
  width: 4rem;
  margin-left: 1rem;
}

.modal_content_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.message_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}

.current_prut_cell_container {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: center;
}

.current_prut_cell_input {
  width: 5.5rem;
}

.no_margin {
  margin: 0;
}

.alert_success {
  background-color: var(--alert-success);
  border: 0.0625rem solid var(--alert-success-border);
}

.alert_error {
  background-color: var(--alert-error);
  border: 0.0625rem solid var(--alert-error-border);
}

.loading_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 27.75rem;
}

.edit_current_prut_actions {
  text-align: right;
  width: 100%;
}

.edit_current_prut_actions_with_title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.prut_table_footer_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1.5rem;
}

.prut_table_total_percentage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 15.5%;
  padding: 0 1rem;
}