.search {
  display: flex;
  align-self: flex-end;
  width: 16.5rem;
}

.contributors_name {
  display: inline-block;
  max-width: 31.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--link-color);
  cursor: pointer;
}

.no_padding {
  padding: 0;
}

.search_wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: flex-end;
}

.actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dividerVertical {
  height: 12px;
  border: 1px solid #0000000F;
}