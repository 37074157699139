.sider_menu {
    height: calc(100vh - 4.5rem);
}

.body_container {
    padding: 1.5rem;
    height: calc(100vh - 4.5rem);
    overflow-x: scroll ;
}

.body_content {
    background-color: var(--body-content-bg);
    padding: 1.5rem;
}
