.page_container {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.page_header {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.page_header_title_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

h4.page_header_title {
    margin-bottom: 0;
}

.card_body_container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.body_title_header {
    display: inline-block;
    width: 10rem;
}

.project_contributors_header {
    display: flex;
    align-items: center;
    gap: 6px;
}

.project_contributors_header h4.page_header_title {
    margin-top: 0;
}

.project_contributors_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.body_description {
    display: flex;
}

.body_description .body_title_header {
    flex-shrink: 0;
}

.body_description .body_description_value {
    white-space: pre-line;
}