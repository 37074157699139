.tooltip_icon {
  color: var(--primary-color);
}

.body_title_header_with_tooltip {
  display: inline-flex;
  width: 15rem;
  gap: 0.25rem;
}

.card_body_container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.lead_approval_status_container {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}

.lead_approval_status_value {
  flex-grow: 1;
}

.lead_approval_status_edit_btn {
  color: var(--primary-color);
}
