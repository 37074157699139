.page_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.reference_container {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.reference_list {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-bottom: 0;
    padding-left: 1.5rem;
}

.image_container {
    display: block;
}
