.page_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.button_container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch; 
    gap: .5rem;
}

.alert_success {
    background-color: var(--alert-success);
    border: 0.0625rem solid var(--alert-success-border);
}

.alert_error {
    background-color: var(--alert-error);
    border: 0.0625rem solid var(--alert-error-border);
}