.search {
  display: flex;
  align-self: flex-end;
  width: 16.5rem;
}

.no_padding {
  padding: 0;
}

.search_wrapper {
  display: flex;
  gap: 12px;
  margin-left: auto;
}

.search_wrapper_title {
  font-weight: 500;
  font-size: 16px;
}
.search_wrapper_parent {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dividerVertical {
  height: 12px;
  border: 1px solid #0000000F;
}

.total_percentage {
  font-weight: 700;
}

.alert_msg {
  margin: 8px 0px 16px 0px;
}

.text_end {
  text-align: end;
}

.table_total_percentage {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list {
  margin-bottom: 0px;
}

.mt_16 {
  margin-top: 16px;
  display: block;
}