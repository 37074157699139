.card_body_container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.tooltip_icon {
  color: var(--primary-color);
}

.lead_progress_header {
  display: inline-block;
  width: 20rem;
}

.lead_progress_header_with_tooltip {
  display: inline-flex;
  width: 20rem;
  gap: 0.25rem;
}
