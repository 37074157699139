.total_percentage {
  font-weight: 700;
}

.error_text {
  margin-top: 8px;
  display: block;
}

.alert_consultant {
  margin-bottom: 16px;
}

.text_end {
  text-align: end;
}

.w_100 {
  width: 100%;
}

.table_total_percentage {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alert_success {
  background-color: var(--alert-success);
  border: 0.0625rem solid var(--alert-success-border);
}