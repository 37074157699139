.modal_title {
  margin-bottom: 0 !important;
}

.modal_content_container {
  min-height: 86px;
}

.modal_content_container :global(.ant-form-item) {
  margin-bottom: 0;
}

.modal_content_container :global(.ant-divider) {
  margin-top: 16px;
  margin-bottom: 10px;
}

.modal_loading_wrapper {
  min-height: 134px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_loading>svg {
  fill: black;
}

.tag_wrapper {
  padding-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.tag_wrapper :global(.ant-tag) {
  margin-right: 0;
}