.contributor_upn {
    color: var(--link-color);
    cursor: pointer;
}

.no_padding {
    padding: 0;
}

.actions {
    display: flex;
    align-items: center;
    gap: 16px;
}