.page_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.title_container {
    display: flex;
    justify-content: space-between;
}

h4.page_title {
    margin-bottom: 0;
}

.summary_title {
    display: flex;
    gap: .25rem;
}

.tooltip_icon {
    color: var(--primary-color);
}

.tooltip_text {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.tooltip_text > span {
    color: var(--tooltip-text-color);
}

.card_container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.bonus_details_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.bonus_details_item_container {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    align-self: stretch; 
}

.bonus_details_item_title_container {
    display: flex;
    min-width: 18.75rem;
    justify-content: space-between;
    align-items: flex-start; 
}

.bonus_details_item_title {
    display: flex;
    gap: 0.25rem;
}

.no_margin {
    margin: 0;
}

.bonus_container {
    display: flex;
    gap: 7.5rem;
}

.bonus_details_item_text {
    white-space: pre-line;
}