.header {
    display: flex;
    height: 4.5rem;
    line-height: normal;
    padding: 1rem 1.5rem;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch; 
    background-color: var(--header-bg);
    box-shadow: var(--box-shadow);
}

.header_body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch; 
}

.header_logo {
    height: 2.5rem;
    cursor: pointer;
}

.logout_modal_title {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.logout_modal_title > h5 {
    margin-bottom: 0;
}

.logout_modal_title_icon {
    color: var(--warning-color);
}