.layout_container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../assets/Login.svg);
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login_card {
  position: absolute;
  width: 24.125rem;
  display: inline-flex;
  padding: 2.5rem 3.75rem;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  border-radius: 0.5rem;
  background: var(--login-card-bg);
  box-shadow: var(--login-box-shadow);
  text-align: center;
}
