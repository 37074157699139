.page_container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.page_header {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.page_header_title_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.tooltip_icon {
  color: var(--primary-color);
}

h4.page_header_title {
  margin-bottom: 0;
}

.card_body_container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.body_title_header {
  display: inline-block;
  width: 15rem;
}

.body_title_header_with_tooltip {
  display: inline-flex;
  width: 15rem;
  gap: 0.25rem;
}

.lead_owner_container {
  display: flex;
  align-items: center;
}

.lead_owner_header {
  display: inline-block;
  width: 20rem;
}

.lead_owner_value {
  display: inline-block;
  flex-grow: 1;
}

.body_description {
  display: flex;
}

.body_description .body_title_header {
  flex-shrink: 0;
}

.body_description .body_description_value {
  white-space: pre-line;
}