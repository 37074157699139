.loading_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 27.75rem;
}

.member_badge__svg {
  width: 10px;
  height: 10px;
  cursor: pointer;
  margin-left: 3px;
}

.member_badge_cont {
  border: 1px solid #D9D9D9;
  display: flex;
  align-items: center;
  background: #FAFAFA;
  padding: 1px 8px;
}

.member_badge_parent_cont {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 16px;
}

.w_100 {
  width: 100%;
}

.alert_success {
  background-color: var(--alert-success);
  border: 0.0625rem solid var(--alert-success-border);
}

.error_text {
  color: red;
  margin-top: 8px;
}