.tooltip_icon {
  color: var(--primary-color);
}

.card_body_container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.body_title_header {
  display: inline-block;
  width: 15rem;
}

.body_title_header_with_tooltip {
  display: inline-flex;
  width: 15rem;
  gap: 0.25rem;
}

.body_description {
  display: flex;
}

.body_description .body_title_header {
  flex-shrink: 0;
}

.body_description .body_description_value {
  white-space: pre-line;
}
