.lead_title {
  display: inline-block;
  max-width: 31.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--link-color);
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  text-decoration: none;
}

.no_padding {
  padding: 0;
}

.actions_column {
  display: flex;
  align-items: center;
  gap: 16px;
}
