:root {
  --header-bg: #ffffff;
  --body-content-bg: #ffffff;
  --box-shadow: 0 -0.0625rem 0 0 #F0F0F0 inset;
  --text-color: rgba(0, 0, 0, 0.85);
  --caption-color: rgba(0, 0, 0, 0.45);
  --login-card-bg: rgba(255, 255, 255, 0.6);
  --login-box-shadow: 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.15);
  --alert-success: #ebf8f2;
  --alert-error: #fef0ef;
  --alert-error-border: #fcdbd9;
  --alert-success-border: #cfefdf;
  --link-color: #1890ff;
  --primary-color: #1890ff;
  --tooltip-text-color: #ffffff;
  --warning-color: #FFC53D;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
